import { ref } from 'vue' 
const installable = ref(false);
function installApp(){
    window.installEvent.prompt();
}
export { installable, installApp };

declare global {
    interface Window {
        installEvent:any;
    }
} 