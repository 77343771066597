import { loadingIndicator } from './utility/utility'
const directives = {
    'progressbar': (el:any, binding:any, vnode:any)=>{ 
        loadingIndicator.api = window.mdc.linearProgress.MDCLinearProgress.attachTo(el);
        loadingIndicator.api.determinate = false;
        loadingIndicator.api.close();
    },
    'btn': (el:any, binding:any, vnode:any) => {
        // this will be called as `mounted` and `updated` 
        window.mdc.ripple.MDCRipple.attachTo(el);
    },
    'txt': (el:any, binding:any, vnode:any) => { 
        window.mdc.textField.MDCTextField.attachTo(el); 
    },
    'switch': (el:any) => { 
        window.mdc.switchControl.MDCSwitch.attachTo(el); 
    },
    'notice':(el:any) => { 
        const notice = window.mdc.snackbar.MDCSnackbar.attachTo(el);    
        notice.open();
     } 
} as any[string]
export { directives }


declare global {
    interface Window {
        mdc:any;
    }
    interface document {
        webkitExitFullscreen: any,
        msExitFullscreen: any
    }
}   