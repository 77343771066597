import { VideoPlaylist } from '@hsi/video-playlist';
import { reactive, ref } from 'vue';
import router from '../router'; 
import store from './index';
const title = ref('');
const icon = ref('');
const tennant = localStorage.getItem('company') || 'ashi'; 
var playlistStore = ref([] as VideoPlaylist.Core.Interfaces.Playlist[]);
var controllerStore = reactive([] as VideoPlaylist.Core.Services.PlaylistController[]);  

async function logout(){ 
    const authenticationService = store.state.authenticationService; 
    await authenticationService.logOut();
    router.push({ name:'Login', params: { company: tennant } }); 
    playlistStore = ref([] as VideoPlaylist.Core.Interfaces.Playlist[]);
    controllerStore = reactive([] as VideoPlaylist.Core.Services.PlaylistController[]);  
  }
  
export { title, icon, playlistStore, controllerStore, logout };