import { createApp } from 'vue'
import App from './components/app/app.vue'
import './registerServiceWorker'
import router from './router'
import store from './store' 
import { directives } from './directives'

const app = createApp(App);
app.use(store);
app.use(router);
Object.keys(directives).forEach(directiveName => app.directive(directiveName, directives[directiveName])); 
app.mount('#platform');