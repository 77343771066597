import { VideoPlaylist } from '@hsi/video-playlist';
function displayMessage(options:MessageOptions){
    const notice = new window.mdc.snackbar.MDCSnackbar(document.querySelector('.mdc-snackbar'));  
    notice.labelText = options.text;
    notice.actionButtonText = options.actionText || 'OK';
    notice.timeoutMs = options.duration;
    notice.open(); 
}
interface MessageOptions{
    text:string,
    actionText:string,
    duration:number
}
var loadingIndicator:any = { api: null };
function resolveVideoQuality(){
    let videoQuality = VideoPlaylist.Core.Services.AvailableFormat.MEDIUM;
    if (localStorage.getItem('videoQuality') === 'hd')
        videoQuality = VideoPlaylist.Core.Services.AvailableFormat.HIGH;
    else if (localStorage.getItem('videoQuality') === 'sd')
        videoQuality = VideoPlaylist.Core.Services.AvailableFormat.STANDARD;
    return videoQuality;
}
export { displayMessage, loadingIndicator, resolveVideoQuality }