import { onMounted, computed, ref, watch, reactive } from 'vue'; 
import { useRoute } from 'vue-router';
import router from '../../router'; 
import { displayMessage } from '../../utility/utility';
import { installable } from '../../utility/appInstall';
import { title, icon, logout } from '../../store/appShared';
import { autoPlay, fullScreenVideo, toggleFullScreenVideo, displayCaptions, toggleAutoPlay, toggleDisplayCaptions, captionLanguage, setCaptionLanguage, videoQuality, setVideoQuality } from '../../utility/appSettings';
import { VideoPlaylist } from '@hsi/video-playlist';

export default {
    setup(){  
      const route = useRoute();
      const routeName = computed(() => route.name);
      const displayUpdate = ref(false); 
      const fullscreen = ref(false);
      const sideLayerOpen = ref(false); 
      const tennant = localStorage.getItem('company') || 'ashi'; 
      var initialRouteRequest = true;
      const _this = this;
      let registration:any;
      let refreshing = false;
      let videoPlaylistClientRepository = new VideoPlaylist.Infrastructure.Repositories.VideoPlaylistIndexedDbClientRepository();
     
      onMounted(()=>{   
        registerEventHandlers();
          watch(routeName, (routeName:any)=>{ 
            if(initialRouteRequest){
              if(!routeName)
                processRouteRedirect();
              initialRouteRequest = false;
            }
          });
      }); 
      
      const _captionLanguage = captionLanguage.value;
      const _videoQuality = videoQuality.value;
      return { 
        title,
        icon,
        logout,
        directory,
        routeName, 
        displayUpdate,
        refreshApp,
        fullscreen,
        installable,
        toggleFullScreen,
        sideLayerOpen,
        autoPlay,
        fullScreenVideo,
        toggleFullScreenVideo,
        displayCaptions,
        toggleDisplayCaptions,
        toggleAutoPlay,
        deleteDownloadedPlaylists,
        _captionLanguage,
        _videoQuality,
        setCaptionLanguage,
        setVideoQuality,
        install
      }

      function processRouteRedirect(){ 
          router.push({ name:'Login', params: { company: tennant } });
      }

      function updateAvailable(event:any) { 
        displayUpdate.value = true;
        registration = event.detail
      }

      function refreshApp() { 
        displayUpdate.value = false
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!registration || !registration.waiting) return
        // Send message to SW to skip the waiting and activate the new SW
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      } 
      
      function registerEventHandlers(){
        window.addEventListener('beforeinstallprompt', (e) => {        
          console.log(`Application can be installed.`);
          installable.value = true;
          // Prevent the mini-infobar from appearing on mobile 
          e.preventDefault(); 
          window.installEvent = e;  
        });

        window.addEventListener('swUpdated', updateAvailable, { once: true });
        if(navigator.serviceWorker)
          navigator.serviceWorker.addEventListener('controllerchange', () => { 
              if (refreshing) return
                refreshing = true 
              window.location.reload()
          }); 
      }

      async function deleteDownloadedPlaylists(){
        await videoPlaylistClientRepository.deleteTrainingElementObjectStore();
        displayMessage({
          text: "All downloaded playlists have been removed successfully.",
          actionText: "OK",
          duration: 4000
        });
        const event = new Event('syncUpdate'); 
        window.dispatchEvent(event);
      }

      function directory(){
        router.push({name:'Directory'});
      }
      
      function install(){
        router.push({name:'Install', params: {company: tennant} });
      }


      function toggleFullScreen(){   
        const elem =  document.documentElement;
        if (!document.fullscreenElement && !document.mozFullScreenElement &&
          !document.webkitFullscreenElement && !document.msFullscreenElement) {
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          }  
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          }
        }
      }
    }
  }
  
  declare global {
      interface Window {
          _wq:any;
          Wistia: any;
          mdc:any;
          snackBar:any; 
          installEvent:any;
          fullScreen: boolean; 
      }
      interface Document { 
          msFullscreenElement: any,
          msExitFullscreen: ()=> void;
          mozCancelFullScreen: () => void;
          webkitExitFullscreen: () => void;
          fullscreenElement: () => void;
          mozFullScreenElement: () => void;
          webkitFullscreenElement: () => void;
      }
      interface HTMLElement {
          webkitRequestFullscreen:() => void;
          msRequestFullscreen:() => void;
          mozRequestFullScreen:() => void;
      }
  }