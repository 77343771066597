import { createStore } from 'vuex'
import { Authentication, Common, FeatureFlag } from '@hsi/ui-core'
import { configuration } from '../config'  
import { displayMessage } from '../utility/utility';
import router from '../router'

const tennant = localStorage.getItem('company') || 'ashi'; 
const tokenProvider = new Authentication.Infrastructure.WebStorageTokenProvider('authToken');             
const microserviceHttpClient = new Common.Infrastructure.MicroserviceClient(tokenProvider);

const authClient = new Authentication.Infrastructure.HsiResourceOwnerRef30Client(
  microserviceHttpClient,
  configuration.authentication.server,
  configuration.authentication.clients.hsiResourceOwnerRef30.secret,
  configuration.authentication.clients.hsiResourceOwnerRef30.scope
);   

const administrationRepository = new Common.Infrastructure.AdministrationMicroservice(
  microserviceHttpClient, 
  configuration.microservice.administration); 

const authenticationService = new Authentication.Core.AuthenticationService(
  authClient, 
  administrationRepository,
  tokenProvider,
  ()=> {
     router.push({ name:'Login', params: { company: tennant } });
     displayMessage({
          text: 'Your session has expired.  Please reauthenticate to continue.',
          actionText: "OK",
          duration: -1
      })  
  });

const featureFlagProvider = new FeatureFlag.Core.FeatureFlagProvider(
  new FeatureFlag.Infrastructure.FeatureFlagMicroservice(
      microserviceHttpClient, 
      configuration.microservice.featureFlagging)
); 
 
export default createStore({
  state: {
    authenticationService: authenticationService,
    featureFlagProvider: featureFlagProvider,
    microserviceHttpClient: microserviceHttpClient,
    tokenProvider: tokenProvider, 
    installable: false
  },
  mutations: {
    installable(state){
      state.installable = true;
    }
  },
  actions: {
  },
  modules: {
  }
})
