<template>
  <div id="app">   
  <div class="header">
      <div class="logo">
        <img src="assets/logo_white.png"/> 
      </div>
      <div class="seperator"></div>
      <div class="page-title hide-mobile">
        <div style="display:flex; margin-left:-10px; margin-top:5px">
          <div class="material-icons">{{icon}}</div> 
          <div class="clip" style="margin-top:-3px;margin-left:8px;">{{title}}</div></div>
      </div> 
      <div class="controls"> 
        <div class="md-tooltip--bottom" data-md-tooltip="Install App"><i tabindex="1" v-if="installable" class="hide-mobile material-icons mdc-top-app-bar__action-item mdc-icon-button hide-mobile" @click="install();" aria-label="Install App">system_update</i></div>
        <div class="md-tooltip--bottom" data-md-tooltip="Update Available"><i tabindex="1" v-if="displayUpdate" class="hide-mobile material-icons mdc-top-app-bar__action-item mdc-icon-button hide-mobile" @click="refreshApp();" aria-label="System Update">update</i></div>
        <div class="md-tooltip--bottom" data-md-tooltip="Settings"><i tabindex="1" class="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Settings" @click="sideLayerOpen = !sideLayerOpen;">video_settings</i></div>
        <div class="md-tooltip--bottom" data-md-tooltip="Full Screen"><i tabindex="1" id="full" class="hide-mobile material-icons mdc-top-app-bar__action-item mdc-icon-button hide-mobile" @click="toggleFullScreen();" aria-label="Full Screen Mode">{{fullscreen ? 'fullscreen_exit': 'fullscreen'}}</i></div>
        <div class="md-tooltip--bottom" data-md-tooltip="Log Out"><i v-if="routeName === 'Directory'" tabindex="1" class="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Log Out" @click="logout()">logout</i></div>
        <div class="md-tooltip--bottom" data-md-tooltip="Close"><i v-if="['Playlist','PlaylistDefault','Install'].indexOf(routeName) !== -1" tabindex="1" class="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Log Out" @click="directory()">close</i></div>
      </div> 
  </div>
  <div role="progressbar" v-progressbar class="mdc-linear-progress" aria-label="Progress Bar" aria-valuemin="0" aria-valuemax="1" aria-valuenow="0" style="margin-top:-8px;z-index:1000;">
    <div class="mdc-linear-progress__buffer">
      <div class="mdc-linear-progress__buffer-bar"></div>
      <div class="mdc-linear-progress__buffer-dots"></div>
    </div>
    <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
      <span class="mdc-linear-progress__bar-inner"></span>
    </div>
    <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
      <span class="mdc-linear-progress__bar-inner"></span>
    </div>
  </div>
  <div class="side-layer animate-default" :class="{'open': sideLayerOpen }">
      <div class="side-layer-skrim animate-default" @click="sideLayerOpen = false;"></div>
      <div class="side-layer-content animate-default">
        <div class="settings-header">
          <i tabindex="1" class="material-icons" aria-label="Settings">video_settings</i>
          <div style="margin-left:8px;flex:1;">Settings</div>
          <i style="margin-right:28px;" tabindex="1" class="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Settings" @click="sideLayerOpen = !sideLayerOpen;">close</i>
        </div>
        <ul class="mdc-list">
            <li class="mdc-list-item" tabindex="-1" @click="toggleAutoPlay()">
                <div style="padding:16px 8px;display:flex;flex-direction:row;width:100%;">
                    <label for="autoplay-switch" style="flex:1;text-align:left;margin-right:16px;">Auto Play</label>
                    <span class="mdc-list-item__ripple"></span>
                    <span class="mdc-list-item__text"> 
                        <div class="mdc-switch" v-switch>
                            <div class="mdc-switch__track"></div>
                            <div class="mdc-switch__thumb-underlay">
                                <div class="mdc-switch__thumb"></div>
                                <input type="checkbox" :checked="autoPlay" id="autoplay-switch" class="mdc-switch__native-control" role="switch" :aria-checked="autoPlay">
                            </div>
                        </div>
                    </span>
                </div>
            </li>
               <li class="mdc-list-item" tabindex="-1" @click="toggleFullScreenVideo()">
                <div style="padding:16px 8px;display:flex;flex-direction:row;width:100%;">
                    <label for="fullScreen-switch" style="flex:1;text-align:left;margin-right:16px;">Display Videos in Full Screen</label>
                    <span class="mdc-list-item__ripple"></span>
                    <span class="mdc-list-item__text"> 
                        <div class="mdc-switch" v-switch>
                            <div class="mdc-switch__track"></div>
                            <div class="mdc-switch__thumb-underlay">
                                <div class="mdc-switch__thumb"></div>
                                <input type="checkbox" :checked="fullScreenVideo" id="fullScreenVideo-switch" class="mdc-switch__native-control" role="switch" :aria-checked="fullScreenVideo">
                            </div>
                        </div>
                    </span>
                </div>
            </li>
            <li class="mdc-list-item" tabindex="-1">
                  <div style="padding:8px 8px;display:flex;flex-direction:row;width:100%;">
                  <label for="" style="flex:1;text-align:left;margin-right:16px;flex:1;" class="">Video Quality</label>
                  <div style="margin-top:-8px">
                    <div class="mdc-form-field" style="margin-right:10px;">
                      <div class="mdc-radio">
                        <input class="mdc-radio__native-control" v-model="_videoQuality" v-on:change="setVideoQuality(_videoQuality)" value="sd" type="radio" id="radio-1" name="videoQualityRadio">
                        <div class="mdc-radio__background">
                          <div class="mdc-radio__outer-circle"></div>
                          <div class="mdc-radio__inner-circle"></div>
                        </div>
                        <div class="mdc-radio__ripple"></div>
                      </div>
                      <label for="radio-1">SD</label>
                    </div>
                    <div class="mdc-form-field" style="margin-right:10px;">
                      <div class="mdc-radio">
                        <input class="mdc-radio__native-control" v-model="_videoQuality" v-on:change="setVideoQuality(_videoQuality)" value="md" type="radio" id="radio-1" name="videoQualityRadio">
                        <div class="mdc-radio__background">
                          <div class="mdc-radio__outer-circle"></div>
                          <div class="mdc-radio__inner-circle"></div>
                        </div>
                        <div class="mdc-radio__ripple"></div>
                      </div>
                      <label for="radio-1">MD</label>
                    </div>
                    <div class="mdc-form-field">
                      <div class="mdc-radio">
                        <input class="mdc-radio__native-control" v-model="_videoQuality" v-on:change="setVideoQuality(_videoQuality)" value="hd" type="radio" id="radio-2" name="videoQualityRadio">
                        <div class="mdc-radio__background">
                          <div class="mdc-radio__outer-circle"></div>
                          <div class="mdc-radio__inner-circle"></div>
                        </div>
                        <div class="mdc-radio__ripple"></div>
                      </div>
                      <label for="radio-2">HD</label>
                    </div>
                  </div>
                </div>
            </li>
            <li class="mdc-list-item" tabindex="-1" @click="toggleDisplayCaptions()">
                <div style="padding:16px 8px;display:flex;flex-direction:row;width:100%;">
                <label for="captions-switch" style="flex:1;text-align:left;margin-right:16px;">Display Captions</label>
                <span class="mdc-list-item__ripple"></span>
                <span class="mdc-list-item__text"> 
                    <div class="mdc-switch" v-switch>
                        <div class="mdc-switch__track"></div>
                        <div class="mdc-switch__thumb-underlay">
                            <div class="mdc-switch__thumb"></div>
                            <input type="checkbox" :checked="displayCaptions" id="captions-switch" class="mdc-switch__native-control" role="switch" :aria-checked="displayCaptions">
                        </div>
                    </div>
                </span>
                </div>
            </li> 
            <li class="mdc-list-item" tabindex="-1">
                  <div style="padding:8px 8px;display:flex;flex-direction:row;width:100%;">
                  <label for="" style="flex:1;text-align:left;margin-right:16px;flex:1;" class="">Caption Language</label>
                  <div style="margin-top:-8px">
                    <div class="mdc-form-field" style="margin-right:10px;">
                      <div class="mdc-radio">
                        <input class="mdc-radio__native-control" v-model="_captionLanguage" v-on:change="setCaptionLanguage(_captionLanguage)" value="en" type="radio" id="radio-1" name="captionLanguageRadio">
                        <div class="mdc-radio__background">
                          <div class="mdc-radio__outer-circle"></div>
                          <div class="mdc-radio__inner-circle"></div>
                        </div>
                        <div class="mdc-radio__ripple"></div>
                      </div>
                      <label for="radio-1">English</label>
                    </div>
                    <div class="mdc-form-field">
                      <div class="mdc-radio">
                        <input class="mdc-radio__native-control" v-model="_captionLanguage" v-on:change="setCaptionLanguage(_captionLanguage)" value="es" type="radio" id="radio-2" name="captionLanguageRadio">
                        <div class="mdc-radio__background">
                          <div class="mdc-radio__outer-circle"></div>
                          <div class="mdc-radio__inner-circle"></div>
                        </div>
                        <div class="mdc-radio__ripple"></div>
                      </div>
                      <label for="radio-2">Spanish</label>
                    </div>
                  </div>
                </div>
            </li>
        </ul>

        <div style="margin:32px 16px;">
        <button class="mdc-button mdc-button--raised" style="width:100%;" @click="deleteDownloadedPlaylists();">
          <span class="mdc-button__ripple"></span>
          <i class="material-icons mdc-button__icon" aria-hidden="true">delete_sweep</i>
          <span class="mdc-button__label clip">Delete All Downloaded Playlists</span>
        </button>
        </div>
      </div>
    </div>
    <router-view/> 
    <div class="mdc-snackbar" style="z-index:1000;bottom:40px;">
      <div class="mdc-snackbar__surface" style=" border:solid 2px #444; color:#fff;">
        <div class="mdc-snackbar__label" style="text-align:left;" role="status" aria-live="polite"> 
        </div>
        <div class="mdc-snackbar__actions">
          <button type="button" class="mdc-button mdc-snackbar__action" style="color:#5ba7f2 !important;">
            <div class="mdc-button__ripple"></div>
            <span class="mdc-button__label"></span>
          </button>
        </div>
      </div>
    </div>
  </div> 
</template>
<script lang="ts" src="./app.ts"></script>
<style lang="scss" src="./app.scss"></style>