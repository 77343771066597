import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [ 
  {
    path: '/:company/playlist/:id',
    name: 'PlaylistDefault',
    component: ()=> import('../components/playlist/playlist.vue')
  },
  {
    path: '/:company/playlist',
    name: 'Playlist',
    component: ()=> import('../components/playlist/playlist.vue')
  }, 
  {
    path: '/:company/directory',
    name: 'Directory',
    component: ()=> import('../components/directory/directory.vue')
  },
  {
    path: '/:company/login',
    name: 'Login',
    component: () => import('../components/login/login.vue')
  },
  {
    path: '/:company',
    name: 'root',
    component: () => import('../components/login/login.vue')
  },
  {
    path: '/login',
    name: 'root',
    component: () => import('../components/login/login.vue')
  },
  {
    path: '/:company/install',
    name: 'Install',
    component: () => import('../components/install/install.vue')
  }
  //{
  //  path: '/about',
  //  name: 'About',
  //  // route level code-splitting
  //  // this generates a separate chunk (about.[hash].js) for this route
  //  // which is lazy-loaded when the route is visited.
  //  component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //}
]
/*
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), 
  routes
})*/ 
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL), 
  routes
})
export default router
