import { ref, watch } from 'vue' 
import { displayMessage } from './utility';

const _displayCaptions = localStorage.getItem('displayCaptions') === 'true';
const _captionLanguage = localStorage.getItem('captionLanguage') || 'en';
const _videoQuality:any = localStorage.getItem('videoQuality') ? localStorage.getItem('videoQuality') : 'md';
const _autoPlay = localStorage.getItem('autoPlay') === 'true';
const _fullScreenVideo = localStorage.getItem('fullScreenVideo') === 'true';
const displayCaptions = ref(_displayCaptions);
const autoPlay = ref(_autoPlay);
const fullScreenVideo = ref(_fullScreenVideo);
const offlineMode = ref(!navigator.onLine);
const captionLanguage = ref(_captionLanguage);
const videoQuality = ref(_videoQuality);

window.addEventListener("load", () => {  
    if(!navigator.onLine)
        setOfflineMode();
    localStorage.setItem('videoQuality', _videoQuality);
}); 
window.addEventListener('online', () => {
    offlineMode.value = false; 
    displayMessage({
        text: "Connectivity established, all features are now available.",
        actionText: "OK",
        duration: -1
      });
}); 
window.addEventListener('offlineMode', setOfflineMode); 
window.addEventListener('offline', setOfflineMode);
 
function setOfflineMode(){ 
    offlineMode.value = true;
    displayMessage({
        text: "The application is running in offline mode, some features will be unavailable.",
        actionText: "OK",
        duration: -1
      });
}

function setCaptionLanguage(language:string){
    captionLanguage.value = language;
    localStorage.setItem('captionLanguage', captionLanguage.value.toString());
}

function setVideoQuality(quality:string){
    videoQuality.value = quality;
    localStorage.setItem('videoQuality', videoQuality.value.toString());
}

function toggleAutoPlay(){
    autoPlay.value = !autoPlay.value;
    localStorage.setItem('autoPlay', autoPlay.value.toString());
}


function toggleFullScreenVideo(){
    fullScreenVideo.value = !fullScreenVideo.value;
    localStorage.setItem('fullScreenVideo', fullScreenVideo.value.toString());
}

function toggleDisplayCaptions(){
    displayCaptions.value = !displayCaptions.value;
    localStorage.setItem('displayCaptions', displayCaptions.value.toString());
}

export { offlineMode, displayCaptions, autoPlay, toggleAutoPlay, fullScreenVideo, toggleFullScreenVideo, toggleDisplayCaptions, captionLanguage, setCaptionLanguage, videoQuality, setVideoQuality };
 