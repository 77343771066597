export const configuration = {
    authentication: {
        server: process.env.VUE_APP_AUTH_SERVER,
        clients: {
            hsiResourceOwner: {
                secret: process.env.VUE_APP_HSIRESOURCEOWNER_SECRET,
                scope: process.env.VUE_APP_HSIRESOURCEOWNER_SCOPE,
            },
            hsiResourceOwnerRef30: {
                secret: process.env.VUE_APP_HSIRESOURCEOWNERREF30_SECRET,
                scope: process.env.VUE_APP_HSIRESOURCEOWNERREF30_SCOPE,
            }
        }
    },
    microservice: {
        administration: process.env.VUE_APP_ADMINISTRATION_MICROSERVICE,
        featureFlagging: process.env.VUE_APP_FEATUREFLAGGING_MICROSERVICE,
        trainingResources: process.env.VUE_APP_TRAININGRESOURCES_MICROSERVICE,
    } 
}